/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'



function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Problem`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Developing new features on the canvas is a long, slow and error prone process.
We need to simplify it so new members can catch up quicker and, most importantly, the product can evolve faster`}</p>
    </blockquote>
    <h4>{`Yunfeng Yang`}</h4>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Problem`}</h1>
    <div style={{
      borderLeft: "2px solid pink",
      paddingLeft: 20,
      fontSize: 44
    }}>
  <font>
    Developing new features on the canvas is a{" "}
    <font style={{
          color: "#B81D13",
          fontSize: 66
        }}>long</font>
    {", "}
    <font style={{
          color: "#008450",
          fontSize: 66
        }}>slow</font> and
    <font style={{
          color: "#EFB700",
          fontSize: 66
        }}> error prone </font>
    process. We need to simplify it so new members can catch up
    <font style={{
          color: "#008450",
          fontSize: 66
        }}> quicker </font>
    and, most importantly, the product can evolve
    <font style={{
          color: "#008450",
          fontSize: 66
        }}> faster.</font>
  </font>
    </div>
    <h4>{`Yunfeng Yang`}</h4>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperSlide2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Problem Questions`}</h1>
    <ul>
      <li parentName="ul">{`Why is it long?`}</li>
      <li parentName="ul">{`Why is it slow?`}</li>
      <li parentName="ul">{`Why is it error prone?`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide2.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Tips`}</p>
    <ul>
      <li parentName="ul">{`Division between duration, speed & efficiency`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;

function MDXContentWrapperNotes2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes2.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1,MDXContentWrapperNotes2];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1,MDXContentWrapperSlide2]