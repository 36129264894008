/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'

import { Code } from "./test";

function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`How can we make it faster (to work with)?`}</h1>
    <ul>
      <li parentName="ul">{`Reduce number of Context`}</li>
      <li parentName="ul">{`Make file structure represent html structure`}</li>
      <li parentName="ul">{`Making components dumb again`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Targeted Anchitecture`}</h1>
    <Code file={"FlowBuilder.tsx"} language={"jsx"} mdxType="Code">
  {`
<SavantApp>
    <SavantComponents>
        <Redux.Provider> // Temporary
            <ReactQuery.Provider>
                <ComputeGraphContext.Provider>
                    // Dumb Components
                    <GraphCore/>
                    <Control/>
                    <ConfigPanel/>
                    <Inspector/>
                    <AutomationConfig/>
                    <LibraryPopover/>
                    <LibraryPopover/>
                    <LibraryPopover/>
                    // Dumb Components
                </ComputeGraphContext.Provider>
            </ReactQuery.Provider>
        </Redux.Provider>
    </SavantComponents>
</SavantApp>
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperSlide2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`File Organization`}</h1>
    <Code file={"MyComponent.tsx"} language={"jsx"} mdxType="Code">
  {`
<div>
    <MainPanel />
    <SidePanel />
</div>
`}
    </Code>
    <br />
    <Code file={"MainPanel.tsx"} language={"jsx"} mdxType="Code">
  {`
const MainPanel = () => {
    const query =  useMainQuery()
    const value = useCalculateValueMemo()
    useDoSomethingEffect()
    return (
        <div>
            <h2>{titleGenerator(query.meta}</h2>
            <Header  total={query.data?.total || 0} />
            <List data={query.data?.content || []}  />
        </div>
    )
}
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide2.isMDXComponent = true;

function MDXContentWrapperSlide3(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Code file={"MainPanel.tsx"} language={"jsx"} mdxType="Code">
  {`
MyComponent/
├── MyComponent.tsx
├── MainPanel
│       ├── Header
│       │       └── Header.tsx
│       ├── List
│       │       └── List.tsx
│       ├── MainPanel.tsx
│       ├── hooks
│       │       ├── useCalculateValueMemo.ts
│       │       ├── useDoSomethingEffect.ts
│       │       └── useMainQuery.ts
│       ├── shared
│       │       └── titleGenerator.ts
│       └── titleGenerator.ts
└── SidePanel
    └── SidePanel.tsx
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide3.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`here are some more notes.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;

function MDXContentWrapperNotes2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes2.isMDXComponent = true;

function MDXContentWrapperNotes3(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes3.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1,MDXContentWrapperNotes2,MDXContentWrapperNotes3];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1,MDXContentWrapperSlide2,MDXContentWrapperSlide3]