/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'

import { Code } from "./test";
import boundaries from "./images/boundaries.png";

function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Reasoning`}</h1>
    <ul>
      <li parentName="ul">{`Why is it error prone?`}
        <ul parentName="li">
          <li parentName="ul">{`components are too “smart”`}</li>
          <li parentName="ul">{`state is shared and synced between multiple React Contexts, Redux store and browser events`}</li>
          <li parentName="ul">{`useEffect pattern is used indiscriminately leading to hard to grasp behaviour's`}</li>
        </ul>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Code file={"FlowBuilder.tsx"} language={"jsx"} mdxType="Code">
  {`
<SavantApp>
    <Browser.Provider>
        <SavantComponents>
            <BuilderContext.Provider>
                <Builder />
            </BuilderContext.Provider>
        </SavantComponents>
        <AutomationContext.Provider>
            <AutomationConfig />
        </AutomationContext.Provider>
        <SourcesContext.Provider>
            <LibraryPopover/>
        </SourcesContext.Provider>
        <NodeContext.Provider>
            <LibraryPopover/>
        </NodeContext.Provider>
        <ConnectionsContext.Provider>
            <LibraryPopover/>
        </ConnectionsContext.Provider>
    </Browser.Provider>
</SavantApp>
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperSlide2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Code file={"Builder.tsx"} language={"jsx"} mdxType="Code">
  {`
<Redux.Provider>
    <ReactQuery.Provider>
        <ComputeGraphContext.Provider>
            <FlowContext.Provider>
                <GraphCore />
                <ControlContext.Provider >
                    <Control />
                </ControlContext.Provider>
            </FlowContext.Provider>
            <ConfigurerContext.Provider>
                <EditorContext.Provider>
                    <ConfigPanel />
                </EditorContext.Provider>
            </ConfigurerContext.Provider>
            <InspectorContext.Provider>
                <Inspector />
            </InspectorContext.Provider>
        </ComputeGraphContext.Provider>
    </ReactQuery.Provider>
</Redux.Provider>
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide2.isMDXComponent = true;

function MDXContentWrapperSlide3(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why is it error prone?`}</h2>
    <ul>
      <li parentName="ul">{`Boundaries are not properly defined,
making the code hard to read & to reason about`}</li>
    </ul>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
  <img src={boundaries} style={{
        maxWidth: 600
      }} alt="sdfk" />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide3.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Tips`}</p>
    <ul>
      <li parentName="ul">{`Show Current Architecture`}</li>
      <li parentName="ul">{`Context means not in pure react sense,
but also include rbowser events & redux store`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`here are some more notes.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;

function MDXContentWrapperNotes2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes2.isMDXComponent = true;

function MDXContentWrapperNotes3(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes3.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1,MDXContentWrapperNotes2,MDXContentWrapperNotes3];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1,MDXContentWrapperSlide2,MDXContentWrapperSlide3]