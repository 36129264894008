/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'

import slowNode from "./images/slow_node.png";
import scope from "./images/slow_scope.png";

function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Reasoning`}</h1>
    <ul>
      <li parentName="ul">{`Why is it slow?`}
        <ul parentName="li">
          <li parentName="ul">{`business logic is intertwined with ui logic`}</li>
          <li parentName="ul">{`components are too “smart”`}</li>
          <li parentName="ul">{`file scafolding is not intuitive or inexistent`}</li>
        </ul>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Why is it slow?`}</h1>
    <ul>
      <li parentName="ul">{`components are too complex & files are too big`}</li>
    </ul>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
  <img src={slowNode} style={{
        maxWidth: 600
      }} alt="sdfk" />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperSlide2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Why is it slow?`}</h1>
    <ul>
      <li parentName="ul">{`hook share scope`}</li>
    </ul>
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
  <img src={scope} style={{
        maxWidth: 600
      }} alt="sdfk" />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide2.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;

function MDXContentWrapperNotes2(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */




const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes2.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1,MDXContentWrapperNotes2];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1,MDXContentWrapperSlide2]