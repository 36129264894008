/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'



function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Reasoning`}</h1>
    <ul>
      <li parentName="ul">{`Why is it long?`}
        <ul parentName="li">
          <li parentName="ul">{`full development lifecycle is takes too much time.`}</li>
          <li parentName="ul">{`storybook is running on mocked data.`}</li>
          <li parentName="ul">{`logic is shared between the savant-app & savant-components repository`}</li>
          <li parentName="ul">{`forcing us to go full lifecycle`}</li>
        </ul>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why is it long?`}</h2>
    <ul>
      <li parentName="ul">{`Because it takes too long to test real case scenario`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`lerna exec -- yarn build`}</inlineCode>{` `}<font style={{
              color: "red"
            }}>{`(user 3m26.450s)`}</font></li>
          <li parentName="ul"><inlineCode parentName="li">{`lerna exec -- yarn unpublish`}</inlineCode>{` `}<font style={{
              color: "green"
            }}>{`(user 0m2.602s)`}</font></li>
          <li parentName="ul"><inlineCode parentName="li">{`lerna exec -- yarn publish`}</inlineCode>{` `}<font style={{
              color: "green"
            }}>{`(user 0m3.059s)`}</font></li>
          <li parentName="ul"><inlineCode parentName="li">{`yarn remove ...`}</inlineCode>{` `}<font style={{
              color: "yellow"
            }}>{`(user 0m27.642s)`}</font></li>
          <li parentName="ul"><inlineCode parentName="li">{`yarn add ...`}</inlineCode>{` `}<font style={{
              color: "orange"
            }}>{`(user 0m8.818s)`}</font></li>
          <li parentName="ul"><inlineCode parentName="li">{`yarn develop`}</inlineCode>{` `}<font style={{
              color: "yellow"
            }}>{`(user ~0m30s)`}</font></li>
        </ul>
      </li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Tips`}</p>
    <pre><code parentName="pre" {...{}}>{`    - Explain possible motivation to the mocked data
    - Mention the blurred boundaries between
      smart/dumb components
`}</code></pre>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1]