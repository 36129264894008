/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'



function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`How can we make it better?`}</h1>
    <ul>
      <li parentName="ul">{`Link frontend & backend with Open API`}</li>
      <li parentName="ul">{`Remove emotion usage`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0];


export default [MDXContentWrapperSlide0]