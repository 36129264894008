/* @jsx mdx */
import React from 'react'
import { mdx } from '@mdx-js/react'

import { Code } from "./test";

function MDXContentWrapperSlide0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Solutions`}</h1>
    <ul>
      <li parentName="ul">{`How can we make it short?`}</li>
      <li parentName="ul">{`How can we make it faster?`}</li>
      <li parentName="ul">{`How can we make it better?`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide0.isMDXComponent = true;

function MDXContentWrapperSlide1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`How can we make it shorter?`}</h1>
    <p>{`Moving full development full cycle to the storybook`}</p>
    <Code file={"FlowBuilder.tsx"} language={"jsx"} mdxType="Code">
  {`
<Builder
    client={{ url: "url", headers: { Authentication: "Bearer sdkfjn" } }}
/>
`}
    </Code>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperSlide1.isMDXComponent = true;

function MDXContentWrapperNotes0(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes0.isMDXComponent = true;

function MDXContentWrapperNotes1(props) {
  /* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
  return (<MDXContent {...props} />);
};
MDXContentWrapperNotes1.isMDXComponent = true;


export const notes = [MDXContentWrapperNotes0,MDXContentWrapperNotes1];


export default [MDXContentWrapperSlide0,MDXContentWrapperSlide1]